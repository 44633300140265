<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Sales</li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Sales Order
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Sales Order</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <router-link
          v-if="checkSinglePermission('create_sale_order')"
          :to="{ name: 'new-sales-order' }"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          tag="button"
          >New Order</router-link
        >
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in slipSettings.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            v-model="filterParams.sales_type"
            class="filter-input mr-2 mg-t-5"
            @change="selectSalesType"
          >
            <option value>All Orders</option>
            <option value="1" selected>Running Order</option>
            <option value="2">Completed Order</option>
            <option value="3">Canceled Bills</option>
          </select>

          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @input="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              placeholder="To"
              class="mr-1 filter-input mg-t-5"
              @input="selectEndDate"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-5p">Order ID</th>
              <th
                class="wd-10p"
                v-if="authData.general_setting.mng_system == 1"
              >
                Table No
              </th>
              <th
                :class="
                  authData.general_setting.mng_system == 1 ? 'wd-15p' : 'wd-20p'
                "
              >
                Customer Name
              </th>
              <th class="wd-10p">Order Amt.</th>
              <th class="wd-10p">Advance Amt.</th>
              <th class="wd-10p">Payment Mode</th>
              <th class="wd-10p">Remaining Amt.</th>
              <th
                :class="
                  authData.general_setting.mng_system == 1 ? 'wd-5p' : 'wd-10p'
                "
              >
                Status
              </th>
              <th
                class="wd-10p text-center no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_sale_order',
                    'checkout_sale_order',
                    'edit_sale_order',
                    'cancel_sale_order',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.length > 0">
            <!-- <tr v-if="!removeList.includes(order.id)"> -->
            <tr v-for="(order, index) in dataSets" :key="index">
              <td scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </td>
              <td>{{ order.date }}</td>

              <td>{{ order.prefix }}{{ order.order_id }}</td>

              <td v-if="authData.general_setting.mng_system == 1">
                <span v-if="order.table">{{ order.table.table }}</span>
                <span v-else>No table</span>
              </td>
              <td>{{ order.customer.name }}</td>
              <td>{{ parseDigitForSlip(order.total) }}</td>
              <td>
                {{ parseDigitForSlip(order.paid_amount) }}
              </td>
              <td>
                <span v-if="order.payment_methods">{{
                  order.payment_methods.title
                }}</span
                >&nbsp;
                <span v-if="order.bank">&nbsp;- {{ order.bank.bank }}</span>
              </td>
              <td>{{ parseDigitForSlip(order.due_amount) }}</td>
              <td>
                <span class="tx-primary font-s" v-if="order.status == 1"
                  >Running</span
                >
                <span class="tx-danger font-s" v-if="order.status == 3">
                  Canceled
                  <span v-if="order.refunded == 1">- with refund</span>
                  <span v-else-if="order.refunded == 0">- without refund</span>
                </span>
                <span class="tx-success font-s" v-else-if="order.status == 2"
                  >Completed</span
                >
              </td>
              <td
                class="text-right table-end-item no-print"
                style="display: flex"
                v-if="
                  checkIfAnyPermission([
                    'view_sale_order',
                    'checkout_sale_order',
                    'edit_sale_order',
                    'cancel_sale_order',
                  ])
                "
              >
                <a
                  href="javascript:;"
                  class="mr-3"
                  v-if="checkSinglePermission('view_sale_order')"
                >
                  <i
                    class="fas fa-eye tx-success"
                    title="View Order Slip"
                    @click="view('salesOrderSlip', order.id)"
                  ></i>
                </a>

                <a
                  v-if="
                    order.status == 1 &&
                    checkSinglePermission('checkout_sale_order')
                  "
                  href="javascript:;"
                  class="mr-3 tx-info"
                  title="Order Checkout"
                  @click="$router.push(`/sales/order/checkout/${order.id}`)"
                >
                  <i class="fas fa-shopping-cart" title="Order Checkout"></i>
                </a>

                <a
                  v-if="
                    order.status == 1 &&
                    checkSinglePermission('edit_sale_order')
                  "
                  href="javascript:;"
                  class="mr-3"
                  title="Edit Order"
                  @click="$router.push(`/sales/order/manage/${order.id}`)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <!-- <a href="javascript:;" class="mr-3" title="Delete Order" @click="drop(order.id)">
                  <i class="fa fa-trash"></i>
                </a>-->
                <a
                  href="javascript:;"
                  v-if="
                    order.status == 1 &&
                    checkSinglePermission('cancel_sale_order')
                  "
                >
                  <i
                    class="fa fa-ban tx-danger"
                    title="Cancel Order"
                    v-if="order.paid_amount == 0"
                    @click="cancelSaleOrder(order.id)"
                  ></i>
                  <i
                    class="fa fa-ban tx-danger"
                    title="Cancel Order"
                    v-else-if="order.paid_amount > 0"
                    @click="cancelSaleOrderWithAdvance(order.id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataSets.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <SalesOrderSlip @printSlip="printSlip" />
  </div>
</template>
<script>
import SalesOrderSlip from "./SalesOrderSlip";
import Paginate from "vuejs-paginate";
import Services from "./Services/Service";
import { mapGetters } from "vuex";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import _ from "lodash";

export default {
  components: {
    Paginate,
    SalesOrderSlip,
    SearchDayMonthComponent,
  },
  data() {
    return {
      orderStatus: 1,
      page: 1,
      loading: true,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      error: false,
      errorMessage: "",
      filterParams: {
        pageIndex: 10,
        branch: "",
        sales_type: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
      "authData",
      "processing",
      "today",
    ]),
    ...mapGetters("sales", ["dataSets", "slipSettings"]),
  },
  beforeMount() {
    this.getFilteredData();
    this.pageReload = true;
    Services.ManageSalesOrder.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("sales/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.page = 1;
        this.pageSerialNo = 1;
        this.offset = 0;

        this.getFilteredDataWithoutDates();
      }
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getFilteredDataWithoutDates();
    }, 500),

    //today/month section
    getFilteredData() {
      this.loading = true;
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.error = false;
      Services.ManageSalesOrder.getFilteredSalesOrder(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("sales/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
        })
        .catch((error) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    printSlip() {
      //once the modal is opened popup print window
      window.print();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/sales/order/${id}`);
    },

    cancelSaleOrder(id) {
      var remarks = "";
      this.$swal({
        title: "Do you really want to cancel this sales order ?",
        html: `<label>Remarks: </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // cancel the sales with remarks
          remarks = document.getElementById("textarea").value;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.updatePaymentClosingOnceCanceled(id, remarks);
        }
      });
    },
    cancelSaleOrderWithAdvance(id) {
      var remarks = "";
      var checkboxValue = "";
      this.$swal({
        title: "Do you really want to cancel this sales order ?",
        html: `<label>Remarks: </label>
        <textarea id="textarea" class="swal2-input"></textarea>
        <div class="refund-check">
        <input type="checkbox" id="chk"> Refund Advance
        </div>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // cancel the sales with remarks
          remarks = document.getElementById("textarea").value;
          checkboxValue = document.getElementById("chk").checked;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.updatePaymentClosingOnceCanceled(id, remarks, checkboxValue);
        }
      });
    },

    updatePaymentClosingOnceCanceled(id, remarks, checked = false) {
      Services.ManageSalesOrder.cancelSaleOrder(id, {
        remarks: remarks,
        checked: checked,
      })
        .then((response) => {
          this.dataSets.forEach((item, index) => {
            if (item.id == id) {
              this.dataSets[index]["status"] = 3;
              this.dataSets[index]["refunded"] = checked == true ? 1 : 0;
              this.dataSets[index]["remarks"] = remarks;
              this.dataSets[index]["canceled_date"] = this.today;
            }
          });
          if (response.status == 200) {
            let success = response.data.success_message;
            this.setNotification(success);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            let err = error.response.data.error_message;
            this.setNotification(err);
          }
        });
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    eventMessage(value) {
      if (value.indexOf("Sales item cancelled successfully.") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
